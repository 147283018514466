import React, { useEffect, useState } from 'react';
import { COMPONENTS, DatePicker } from 'sw-ui';
import DatePickerWithTime from 'react-datetime';
import PropTypes from 'prop-types';

import SelectDepartment from '../selectDepartment';
import SelectCompany from '../selectCompany';
import SelectProject from '../selectProject';
import AnalyticsRows from '../analyticsRows';
import EmployeeSuggest from '../../../../components/employee';
import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';
import { UploadVoucher } from '../custom/components/UploadVoucher';

import { getMoment, momentObject } from '../../../../bi/utils/formatDate';
import {
  applyAnalytics,
  removeAnalytics,
  validateAnalytics,
  validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { isMandatory } from '../../../../bi/utils/account';
import { findDepartments } from '../../../../bi/utils/trip';
import { getPassportDueDate, getPassportType } from '../../../../bi/utils/passport';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import {
  DEFAULTDATE, DEFAULTTIME, LOCALERU,
} from '../../../../constants/time';
import { FIELDS, MODELS } from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import { AEROEXPRESS_TARIFF_TYPES } from '../../../../bi/constants/aeroexpress';

import styles from '../../styles/form.module.scss';

const INVALID_FORM_MESSAGE = 'Проверьте корректность ввода данных';

const LABELS = {
  TRANSACION_NUMBER: 'Номер транзакции',
  TICKET_NUMBER: 'Номер билета',
  RESERVATION_DATE: 'Время оплаты (На стороне поставщика)',
  DEPARTURE_DATE: 'Срок действия (Дата от)',
  EMPLOYEES: 'Сотрудник',
  PRICE: 'Стоимость',
  COMMISSION: 'Сбор Smartway',
  TOTAL: 'Цена',
  CONFIRM: 'Подтвердить изменения',
  NO_PASSPORT: 'У сотрудника нет паспорта',
  // eslint-disable-next-line @stylistic/max-len
  NO_VALID_ID: (transitionId) => `Ваучер для транзакции ${transitionId} не найден. Пожалуйста, проверьте корректность ввода номера транзакции или добавьте ваучер вручную`,
  FILE_VOUCHER: 'Выберите файл',
};

const AeroExpressForm = ({
  analytics,
  aeroexpress,
  tripAnalyticsValueIds,
  companies,
  tripService,
  mandatoryProject,
  aeroexpressService,
  projects,
  setTripAnalytics,
  guidService,
  isCopy,
  addTrip,
  onConfirm,
}) => {
  const [Employees, setEmployees] = useState({});
  const [CompanyId, setCompanyId] = useState(0);
  const [ProjectId, setProjectId] = useState(0);
  const [UserAnalytics, setUserAnalytics] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [DepartmentId, setDepartmentId] = useState(0);
  const [EmployeeId, setEmployeeId] = useState(0);
  const [PassportId, setPassportId] = useState(0);
  const [employeesList, setEmployeesList] = useState([]);
  const [DepartureDate, setDepartureDate] = useState(getMoment());
  const [ReservationDate, setReservationDate] = useState('');
  const [TicketNumber, setTicketNumber] = useState();
  const [TransactionId, setTransactionId] = useState();
  const [Commission, setCommission] = useState('');
  const [Price, setPrice] = useState('');
  const [Tariff, setTariff] = useState(AEROEXPRESS_TARIFF_TYPES.STANDARD);
  const [mandatory, setMandatory] = useState(false);
  const [customFile, setCustomFile] = useState([]);
  const [ticketFileId, setTicketFileId] = useState(null);
  const [aeroexpressLoading, setAeroexpressLoading] = useState(false);
  const [initTransitionId, setInitTransitionId] = useState(null);
  const [errorReqFile, setErrorReqFile] = useState(false);
  const [validation, setValidation] = useState({
    CompanyId: '',
    ProjectId: '',
    DepartmentId: '',
    Employees: '',
    analytics: '',
    Price: '',
    TransactionId: '',
    TicketNumber: '',
    ReservationDate: '',
    validFile: '',
    isValid: true,
  });

  const updateTripData = ({
    employeesList: employees,
    customFile: voucher,
    aeroexpressLoading: loading,
    ticketFileId: fileId,
    errorReqFile: errorFile,
  }) => {
    setEmployeesList(employees);
    setCustomFile(voucher);
    setAeroexpressLoading(loading);
    setTicketFileId(fileId);
    setErrorReqFile(errorFile);
  };

  const setStateCopy = (aeroexpressData) => {
    const {
      JsonData: {
        ReservationDate: ReservationDateCached,
        TransactionId: TransactionIdCached,
        Trip: { DepartureDate: DepartureDateCached },
        Ticket: {
          Id,
          TariffType,
        },
        PriceDetails: {
          Base,
          Commission: CommissionCached,
        },
      },
    } = aeroexpressData;

    setEmployeeId(null);
    setReservationDate(momentObject(ReservationDateCached));
    setTransactionId(TransactionIdCached);
    setDepartureDate(momentObject(DepartureDateCached));
    setTicketNumber(Id);
    setTariff(TariffType);
    setPrice(Base);
    setCommission(CommissionCached);
  };

  const setStateEdit = (aeroexpressData) => {
    const {
      Commission: CommissionCached,
      DepartureDate: DepartureDateCached,
      ReservationDate: ReservationDateCached,
      TransactionId: TransactionIdCached,
      TicketNumber: TicketNumberCached,
      Tariff: TariffCached,
      Price: PriceCached,
      EmployeeId: EmployeeIdCached,
      Employees: EmployeesCached,
      CompanyId: CompanyIdCached,
      Departments: DepartmentsCached,
      DepartmentId: DepartmentIdCached,
      PassportId: PassportIdCached,
      ProjectId: ProjectIdCached,
      UserAnalytics: UserAnalyticsCached,
    } = aeroexpressData;

    setCompanyId(CompanyIdCached);
    setEmployees(EmployeesCached);
    setEmployeeId(EmployeeIdCached);
    setDepartments(DepartmentsCached);
    setDepartmentId(DepartmentIdCached);
    setPassportId(PassportIdCached);
    setProjectId(ProjectIdCached);
    setUserAnalytics(UserAnalyticsCached);
    setReservationDate(momentObject(ReservationDateCached));
    setTransactionId(TransactionIdCached);
    setDepartureDate(momentObject(DepartureDateCached));
    setTicketNumber(TicketNumberCached);
    setTariff(TariffCached);
    setPrice(PriceCached);
    setCommission(CommissionCached);
  };

  useEffect(() => {
    const unsubscribe = tripService.subscribe(updateTripData);

    return () => {
      unsubscribe();
      tripService.defaultCustomFile();
    };
  }, []);

  useEffect(() => {
    if (aeroexpress.isEdit) {
      return setStateEdit(aeroexpress);
    }

    if (isCopy) {
      return setStateCopy(aeroexpress);
    }
  }, [isCopy, aeroexpress]);

  const handleSelectCompany = ({ id }) => {
    const result = aeroexpressService.aeroexpressTripFieldValidation(FIELDS.COMPANYID, id);

    setCompanyId(id);
    setEmployees({});
    setMandatory(isMandatory(mandatoryProject, id));
    setValidation((prev) => ({
      ...prev,
      CompanyId: result,
      Employees: '',
    }));
  };

  const handleSelectDepartment = ({ id }) => {
    const result = Departments.length ? aeroexpressService.aeroexpressTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';

    setDepartmentId(id);
    setValidation((prev) => ({
      ...prev,
      DepartmentId: result,
    }));
  };

  const handleSelectProject = ({ id }) => {
    const result = mandatory ? aeroexpressService.aeroexpressTripFieldValidation(FIELDS.PROJECTID, id) : '';

    setProjectId(id);
    setValidation((prev) => ({
      ...prev,
      ProjectId: result,
    }));
  };

  const handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? tripAnalyticsValueIds : UserAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    setUserAnalytics(updatedAnalyticsValues);

    setValidation((prev) => ({
      ...prev,
      analytics: {
        ...validation.analytics,
        ...analyticsValidation,
      },
    }));
  };

  const handleRemoveEmployee = () => {
    setEmployees({});
    setDepartments([]);
    setDepartmentId(0);
  };

  const handleSelectEmployee = async ({ Id }) => {
    const currentItem = await tripService.getEmployeeDataByID(Id);

    const result = tripService.aeroexpressTripFieldValidation(FIELDS.EMPLOYEES, currentItem);

    if (currentItem.Documents.length) {
      setPassportId(currentItem.Documents[0].Id);
    }

    setEmployees(currentItem);
    setDepartments(findDepartments(currentItem, CompanyId));
    setEmployeeId(currentItem.Id);
    setValidation((prev) => ({
      ...prev,
      Employees: result,
    }));
  };

  const handleEmployeeFocus = async () => {
    await tripService.autocompleteEmployees(CompanyId, '');

    setValidation((prev) => ({
      ...prev,
      Employees: +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
    }));
  };

  const handleChangeEmployee = (value) => tripService.autocompleteEmployees(CompanyId, value);

  const renderPassport = () => {
    let selectHtml = <span className={ styles['no-valid'] }>{ LABELS.NO_PASSPORT }</span>;

    if (Employees && Employees.Documents && Employees.Documents.length) {
      const passportHtml = Employees.Documents
        ? Employees.Documents.map((passport, index) => {
          const passportType = getPassportType(Employees.Code, passport.Type);
          const passportDueDate = getPassportDueDate(passport.DueDate);
          const passportDueDateHtml = passportDueDate ? `до ${passportDueDate}` : '';

          return (
            <option
              key={ `${passport.Id}_${index}` }
              value={ passport.Id }
            >
              { `${passportType} -  ${passport.Number} ${passportDueDateHtml}` }
            </option>
          );
        })
        : null;

      selectHtml = (
        <select
          value={ PassportId }
          onChange={ (e) => setPassportId(e.target.value) }
        >
          { passportHtml }
        </select>
      );
    }

    return (
      <div>
        <label>Паспорт</label>
        { selectHtml }
      </div>
    );
  };

  const renderTariff = () => (
    <div className={ styles['col-1-3'] }>
      <div className={ styles.select }>
        <div className={ styles.wrap }>
          <select
            value={ Tariff }
            onChange={ (e) => setTariff(e.target.value) }
          >
            <option
              key={ AEROEXPRESS_TARIFF_TYPES.STANDARD }
              value={ AEROEXPRESS_TARIFF_TYPES.STANDARD }
            >
              Стандарт
            </option>
            <option
              key={ AEROEXPRESS_TARIFF_TYPES.BUSINESS }
              value={ AEROEXPRESS_TARIFF_TYPES.BUSINESS }
            >
              Бизнес
            </option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderTicketNumber = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        <Input
          value={ TicketNumber }
          label={ LABELS.TICKET_NUMBER }
          onChange={ (e) => {
            setValidation((prev) => ({
              ...prev,
              TicketNumber: '',
            }));
            setTicketNumber(e.target.value);
          } }
          valid={ validation.TicketNumber }
        />
      </div>
    </div>
  );

  const renderTransactionId = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        <Input
          value={ TransactionId }
          label={ LABELS.TRANSACION_NUMBER }
          onChange={ (e) => {
            setValidation((prev) => ({
              ...prev,
              TransactionId: '',
            }));
            setTransactionId(Number(e.target.value));
            setErrorReqFile(false);
          } }
          valid={ validation.TransactionId }
        />
      </div>
    </div>
  );

  const renderPrice = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        <Input
          value={ Price }
          label={ LABELS.PRICE }
          onChange={ (e) => {
            setValidation((prev) => ({
              ...prev,
              Price: '',
            }));
            setPrice(Number(e.target.value));
          } }
          valid={ validation.Price }
          placeholder='0'
        />
      </div>
    </div>
  );

  const renderCommission = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        <Input
          value={ Commission }
          label={ LABELS.COMMISSION }
          onChange={ (e) => {
            setValidation((prev) => ({
              ...prev,
              Commission: '',
            }));
            setCommission(Number(e.target.value));
          } }
          valid={ validation.Commission }
          placeholder='0'
        />
      </div>
    </div>
  );

  const renderTotal = () => {
    const price = typeof Price === 'string' ? 0 : Price;
    const commission = typeof Commission === 'string' ? 0 : Commission;
    const total = price + commission;

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <Input
            value={ total }
            disabled
            label={ LABELS.TOTAL }
          />
        </div>
      </div>
    );
  };

  const validationSubmit = () => {
    const companyId = tripService.aeroexpressTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const departmentId = Departments.length > 0 ? tripService.aeroexpressTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';
    const projectId = mandatory ? aeroexpressService.aeroexpressTripFieldValidation(FIELDS.PROJECTID, ProjectId) : '';
    const employees = tripService.aeroexpressTripFieldValidation(FIELDS.EMPLOYEES, Employees);
    const userAnalytics = validateAnalytics(tripAnalyticsValueIds, UserAnalytics, analytics);
    const hasUnsetRequiredUserAnalytics = Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);
    const passportId = tripService.aeroexpressTripFieldValidation(FIELDS.PASSPORT_ID, PassportId);
    const price = tripService.aeroexpressTripFieldValidation(FIELDS.PRICE, Price);
    const transactionId = tripService.aeroexpressTripFieldValidation(FIELDS.TRANSACTION_ID, TransactionId);
    const ticketNumber = tripService.aeroexpressTripFieldValidation(FIELDS.TICKET_NUMBER, TicketNumber);
    const reservationDate = tripService.aeroexpressTripFieldValidation(FIELDS.RESERVATION_DATE, ReservationDate);

    if (
      companyId
      || departmentId
      || projectId
      || employees
      || passportId
      || price
      || ticketNumber
      || hasUnsetRequiredUserAnalytics
      || reservationDate
    ) {
      return {
        isValid: false,
        validation: {
          ...validation,
          CompanyId: companyId,
          ProjectId: projectId,
          DepartmentId: departmentId,
          Employees: employees,
          analytics: userAnalytics,
          Price: price,
          TransactionId: transactionId,
          TicketNumber: ticketNumber,
          ReservationDate: reservationDate,
          isValid: false,
        },
      };
    }

    return { isValid: true };
  };

  const renderReservationDate = () => {
    const validReservationDateHtml = validation.ReservationDate ? <span className='error-msg'>{ validation.ReservationDate }</span> : null;

    return (
      <div className={ styles['col-1-3'] }>
        <DatePickerWithTime
          dateFormat={ DEFAULTDATE }
          timeFormat={ DEFAULTTIME }
          locale={ LOCALERU }
          value={ ReservationDate }
          onChange={ (value) => {
            setValidation((prev) => ({
              ...prev,
              ReservationDate: '',
            }));
            setReservationDate(value);
          } }
          className={ `componentHook ${validation.ReservationDate ? 'no-valid' : ''}` }
        />
        <label>{ LABELS.RESERVATION_DATE }</label>
        { validReservationDateHtml }
      </div>
    );
  };

  const handleConfirmValidation = () => {
    if (errorReqFile) {
      if (customFile[0].file === null) {
        setValidation((prev) => ({
          ...prev,
          validFile: LABELS.FILE_VOUCHER,
          isValid: false,
        }));

        return false;
      }
    }

    return true;
  };

  const getTicketsBlanks = async () => {
    const { getAeroexpressVoucher } = tripService;

    const transactionParam = { transaction_id: TransactionId };

    if (!errorReqFile) {
      const data = await getAeroexpressVoucher(transactionParam);

      if (data && data.tickets_blanks) {
        return data.tickets_blanks[0];
      }

      return null;
    }

    return ticketFileId;
  };

  const uploadFile = (file, ind) => {
    const { uploadCustomFile, postAeroexpressVoucher } = tripService;
    const { ServiceType } = aeroexpress;

    uploadCustomFile(file, ind);
    postAeroexpressVoucher(ServiceType, file);
  };

  const renderUploadVoucher = () => {
    const { resetCustomFile } = tripService;

    if (!errorReqFile) return null;

    const renderWarning = () => {
      if (ticketFileId) return null;

      const transitionId = initTransitionId || '';

      return (
        <span className={ styles.errorInfo }>{ LABELS.NO_VALID_ID(transitionId) }</span>
      );
    };

    return customFile.map((file, ind) => (
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          { renderWarning() }
          <UploadVoucher
            uploadFile={ (item) => uploadFile(item, ind) }
            customFile={ file }
            resetCustomFile={ () => resetCustomFile(ind) }
            valid={ validation.validFile }
          />
        </div>
      </div>
    ));
  };

  const handleConfirm = async () => {
    const { isValid, validation: validationErrors } = validationSubmit();
    const isValidFile = handleConfirmValidation();

    if (isValid && isValidFile) {
      const Guid = guidService.generate();

      const state = {
        Employees,
        CompanyId,
        ProjectId,
        UserAnalytics,
        Departments,
        DepartmentId,
        EmployeeId,
        PassportId,
        employeesList,
        DepartureDate,
        ReservationDate,
        TicketNumber,
        TransactionId,
        Commission,
        Price,
        Tariff,
        mandatory,
        ServiceType: SERVICETYPE.AEROEXPRESS,
      };
      const stateToSave = JSON.parse(JSON.stringify(state));
      stateToSave.Guid = Guid;

      const fileId = await getTicketsBlanks();

      const JsonData = {
        ReservationDate,
        Trip: { DepartureDate },
        TransactionId,
        Ticket: {
          Id: TicketNumber,
          TariffType: Tariff,
          TicketFileId: fileId,
        },
        PriceDetails: {
          ...MODELS.PriceDetails,
          Base: Price,
          Commission: Commission || 0,
        },
        EmployeeIds: [EmployeeId],
      };

      const item = {
        OrderTripItemId: 0,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        EmployeeList: Employees,
        Status: 0,
        ServiceType: SERVICETYPE.AEROEXPRESS,
        UserAnalytics,
        Guid,
        JsonData: JSON.stringify(JsonData),

        indexEdited: aeroexpress.indexEdited,
      };

      setInitTransitionId(TransactionId);

      if (fileId) {
        addTrip(stateToSave);
        onConfirm(item);
      }
    } else {
      scrollToErrMessage();
      setValidation((prev) => ({
        ...prev,
        ...validationErrors,
      }));
    }
  };

  const message = validation.isValid ? '' : INVALID_FORM_MESSAGE;

  return (
    <div className={ styles.wrap }>
      <div className={ styles.row }>
        <h4>Данные ваучера</h4>
        <div className={ styles.row }>
          <SelectCompany
            currentCompanyId={ CompanyId }
            companies={ companies }
            onSelect={ handleSelectCompany }
            validationCompany={ validation.CompanyId }
          />
          <SelectDepartment
            departments={ Departments }
            onSelect={ handleSelectDepartment }
            validationDepartment={ validation.DepartmentId }
            currentDepartmentId={ DepartmentId }
            currentEmployeeId={ EmployeeId }
          />
          <SelectProject
            disabled={ !CompanyId }
            currentProjectId={ ProjectId }
            validationProject={ validation.ProjectId }
            mandatoryProject={ mandatoryProject }
            projects={ projects }
            onSelect={ handleSelectProject }
          />
        </div>
        <AnalyticsRows
          analytics={ analytics }
          serviceAnalyticsValueIds={ UserAnalytics }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          onSelect={ handleSelectAnalytics }
          validation={ validation.analytics }
        />
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <EmployeeSuggest
              items={ employeesList }
              selected={ Employees }
              placeholder={ LABELS.EMPLOYEES }
              onRemove={ handleRemoveEmployee }
              onSelect={ handleSelectEmployee }
              valid={ validation.Employees }
              onFocus={ handleEmployeeFocus }
              onChange={ handleChangeEmployee }
              disablePick={ !CompanyId }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <div className={ styles.select }>
              <div className={ styles.wrap }>
                { renderPassport() }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.row }>
        <h4>Маршрутная квитанция</h4>
        <div className={ styles.row }>
          { renderTariff() }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <label>{ LABELS.DEPARTURE_DATE }</label>
            <DatePicker
              value={ DepartureDate }
              onChange={ ({ value }) => setDepartureDate(value) }
              inputTheme={ COMPONENTS.DATEPICKER.INPUT_THEME.OPEN }
            />
          </div>
          { renderReservationDate() }
        </div>
        { renderTicketNumber() }
        { renderTransactionId() }
        { renderPrice() }
        { renderCommission() }
        { renderTotal() }
        { renderUploadVoucher() }
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              loading={ aeroexpressLoading }
              onClick={ handleConfirm }
              label={ LABELS.CONFIRM }
              inValidMessage={ message }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AeroExpressForm.propTypes = {
  aeroexpress: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  mandatoryProject: PropTypes.array,
  tripService: PropTypes.object.isRequired,
  projects: PropTypes.array,
  analytics: PropTypes.array,
  tripAnalyticsValueIds: PropTypes.array,
  aeroexpressService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  setTripAnalytics: PropTypes.func,
  isCopy: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  addTrip: PropTypes.func.isRequired,
};

AeroExpressForm.defaultProps = {
  projects: [],
  mandatoryProject: [],
  analytics: [],
  tripAnalyticsValueIds: [],
  setTripAnalytics: () => {},
  isCopy: false,
};

export { AeroExpressForm };
